const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api';
} else {
    baseUrl = '/api';
}

export default {
    auth: {
        login: baseUrl + '/auth/login/',
        logout: baseUrl + '/auth/logout/',
        register: {
            basicDetails: {
                add: baseUrl + '/register/basic-details/add/',
                edit: baseUrl + '/register/basic-details/edit/'
            },
            qualificationDetails: {
                add: baseUrl + '/register/qualification-details/add/',
                edit: baseUrl + '/register/qualification-details/edit/'
            },
            reacheInfoDetails: {
                add: baseUrl + '/register/qualification-details/add/',
                edit: baseUrl + '/register/qualification-details/edit/'
            }
        },
        setPassword: baseUrl + '/auth/set-password',
        status: baseUrl + '/auth/status/'
    },
    vueSelect: {
        batchOptions: baseUrl + '/master-data/select/batch/',
        religionOptions: baseUrl + '/master-data/select/religion/',
        languageOptions: baseUrl + '/master-data/select/language/',
        countryOptions: baseUrl + '/master-data/select/country/',
        qualificationsOptions: baseUrl + '/master-data/select/profession/'
    },
    admin: {
        userList: {
            staffList: baseUrl + '/user-list/staff/',
            memberList: baseUrl + '/user-list/members/',
            pendingMemberList: baseUrl + '/user-list/pending-members/',
            volunteerList: baseUrl + '/user-list/pending-volunteers/'
        },
        policy: {
            list: baseUrl + '/policy/list/',
            delete: baseUrl + '/policy/delete/',
            add: baseUrl + '/policy/add/'
        },
        directorFunction: {
            staff: {
                details: baseUrl + '/staff/details/',
                add: baseUrl + '/staff/add/',
                attachment: {
                    add: baseUrl + '/staff-attachment/add/',
                    list: baseUrl + '/staff-attachment/list/',
                    delete: baseUrl + '/staff-attachment/delete/'
                }
            },
            admin: {
                add: baseUrl + '/admin/add/',
                details: baseUrl + '/staff/details/',
                list: baseUrl + '/admin/list/'
            },
            fileManagement: {
                folder: {
                    add: baseUrl + '/folder/add/',
                    list: baseUrl + '/folder/list/',
                    edit: baseUrl + '/folder/edit/',
                    delete: baseUrl + '/folder/delete/'
                },
                subFolder: {
                    add: baseUrl + '/sub-folder/add/',
                    list: baseUrl + '/sub-folder/list/',
                    edit: baseUrl + '/sub-folder/edit/',
                    delete: baseUrl + '/sub-folder/delete/'
                },
                files: {
                    add: baseUrl + '/files/add/',
                    list: baseUrl + '/files/list/',
                    edit: baseUrl + '/files/edit/',
                    delete: baseUrl + '/files/delete/'
                }
            }
        },
        enrollmentFunction: {
            pendingMembers: {
                list: baseUrl + '/pending-members/list/',
                details: baseUrl + '/user-list/details/'
            }
        },
        masterData: {
            leavingStatus: {
                list: baseUrl + '/master-data/leaving-status/list/',
                add: baseUrl + '/master-data/leaving-status/add/',
                edit: baseUrl + '/master-data/leaving-status/edit/',
                delete: baseUrl + '/master-data/leaving-status/delete/'
            },
            memberStatus: {
                list: baseUrl + '/master-data/member-status/list/',
                add: baseUrl + '/master-data/member-status/add/',
                edit: baseUrl + '/master-data/member-status/edit/',
                delete: baseUrl + '/master-data/member-status/delete/'
            },
            profession: {
                list: baseUrl + '/master-data/professions/list/',
                add: baseUrl + '/master-data/professions/add/',
                edit: baseUrl + '/master-data/professions/edit/',
                delete: baseUrl + '/master-data/professions/delete/'
            },
            userType: {
                list: baseUrl + '/master-data/user-types/list/',
                add: baseUrl + '/master-data/user-types/add/',
                edit: baseUrl + '/master-data/user-types/edit/',
                delete: baseUrl + '/master-data/user-types/delete/'
            },
            batch: {
                list: baseUrl + '/master-data/batches/list/',
                add: baseUrl + '/master-data/batches/add/',
                edit: baseUrl + '/master-data/batches/edit/',
                delete: baseUrl + '/master-data/batches/delete/'
            },
            country: {
                list: baseUrl + '/master-data/countries/list/',
                add: baseUrl + '/master-data/countries/add/',
                edit: baseUrl + '/master-data/countries/edit/',
                delete: baseUrl + '/master-data/countries/delete/'
            },
            language: {
                list: baseUrl + '/master-data/languages/list/',
                add: baseUrl + '/master-data/languages/add/',
                edit: baseUrl + '/master-data/languages/edit/',
                delete: baseUrl + '/master-data/languages/delete/'
            },
            religion: {
                list: baseUrl + '/master-data/religions/list/',
                add: baseUrl + '/master-data/religions/add/',
                edit: baseUrl + '/master-data/religions/edit/',
                delete: baseUrl + '/master-data/religions/delete/'
            }
        }
    },
    staff: {
        teachingLinks: {
            list: baseUrl + '/teaching-links/list/',
            delete: baseUrl + '/teaching-links/delete/',
            add: baseUrl + '/teaching-links/add/'
        },
        teachingVideo: {
            list: baseUrl + '/teaching-video/list/',
            delete: baseUrl + '/teaching-video/delete/',
            add: baseUrl + '/teaching-video/add/'
        },
        policy: {
            list: baseUrl + '/policy/list/',
            delete: baseUrl + '/policy/delete/',
            add: baseUrl + '/policy/add/'
        }
    },
    member: {
        accept: baseUrl + '/user-list/edit-reache-info/',
        decline: baseUrl + '/user-list/decline/',
        profileDetails: baseUrl + '/user-list/details/',
        policy: {
            list: baseUrl + '/policy/list/',
            delete: baseUrl + '/policy/delete/',
            add: baseUrl + '/policy/add/'
        }
    },
    user: {
        suspendUsers: baseUrl + '/user-list/suspend/',
        activate: baseUrl + '/user-list/activate/'
    }
};
