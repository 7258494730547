// import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const SupportAvailable = () => import(/* webpackChunkName: "home" */'../views/SupportAvailable');
const HowToJoin = () => import(/* webpackChunkName: "home" */'../views/HowToJoin');
const GettingToReache = () => import(/* webpackChunkName: "home" */'../views/GettingToReache');
const Policies = () => import(/* webpackChunkName: "home" */'../views/Policies');
const Publications = () => import(/* webpackChunkName: "home" */'../views/Publications');
const StudySkills = () => import(/* webpackChunkName: "home" */'../views/StudySkills');
const PersonalWellbeing = () => import(/* webpackChunkName: "home" */'../views/PersonalWellbeing');
const PortfolioAndPdp = () => import(/* webpackChunkName: "home" */'../views/PortfolioAndPdp');
const EnglishOet = () => import(/* webpackChunkName: "home" */'../views/EnglishOet');
const Plab1 = () => import(/* webpackChunkName: "home" */'../views/Plab1');
const Plab2 = () => import(/* webpackChunkName: "home" */'../views/Plab2');
const DrugOfTheWeek = () => import(/* webpackChunkName: "home" */'../views/DrugOfTheWeek');
const WorkingInTheNhs = () => import(/* webpackChunkName: "home" */'../views/WorkingInTheNhs');
const ClinicalCommunication = () => import(/* webpackChunkName: "home" */'../views/ClinicalCommunication');
const GmcResources = () => import(/* webpackChunkName: "home" */'../views/GmcResources');
const ResourcesForMembers = () => import(/* webpackChunkName: "home" */'../views/ResourcesForMembers');
const HELP = () => import(/* webpackChunkName: "home" */'../views/Help');
const Donation = () => import(/* webpackChunkName: "home" */'../views/Donation');
const Englishpractice = () => import(/* webpackChunkName: "home" */'../views/English-practice');
const Consultants = () => import(/* webpackChunkName: "home" */'../views/Consultants');
const GeneralPractitioners = () => import(/* webpackChunkName: "home" */'../views/General-Practitioners');
const JuniorDoctors = () => import(/* webpackChunkName: "home" */'../views/Junior-Doctors');
const MedicalStudents = () => import(/* webpackChunkName: "home" */'../views/Medical-Students');
const OtherProfessionals = () => import(/* webpackChunkName: "home" */'../views/Other-Professionals');
const Contact = () => import(/* webpackChunkName: "home" */'../views/contact');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    // beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/links-to-resources/',
            name: 'Resources for Members',
            component: ResourcesForMembers
        },
        {
            path: '/about/support/',
            name: 'Support Available',
            component: SupportAvailable
        },
        {
            path: '../about/how-to-join.html',
            name: 'How To Join',
            component: HowToJoin
        },
        {
            path: '/about/directions/',
            name: 'Getting To Reache',
            component: GettingToReache
        },
        {
            path: '/about/policies/',
            name: 'Policies',
            component: Policies
        },
        {
            path: '/about/presenting-our-work/',
            name: 'Publications',
            component: Publications
        },
        {
            path: '/links-to-resources/self-management-study-skills/',
            name: 'Study Skills',
            component: StudySkills
        },
        {
            path: '/links-to-resources/personal-well-being/',
            name: 'Personal Wellbeing',
            component: PersonalWellbeing
        },
        {
            path: '/links-to-resources/portfolio-cv-and-pdp/',
            name: 'Portfolio and PDP',
            component: PortfolioAndPdp
        },
        {
            path: '/links-to-resources/english-oet/',
            name: 'English OET',
            component: EnglishOet
        },
        {
            path: '/links-to-resources/plab-1/',
            name: 'Plab 1',
            component: Plab1
        },
        {
            path: '/links-to-resources/plab-2-resources/',
            name: 'Plab 2',
            component: Plab2
        },
        {
            path: '/links-to-resources/drug-of-the-week-resources/',
            name: 'Drug Of The Week',
            component: DrugOfTheWeek
        },
        {
            path: '/links-to-resources/preparing-for-work-in-the-nhs/',
            name: 'Working in the NHS',
            component: WorkingInTheNhs
        },
        {
            path: '/links-to-resources/safe-and-effective-clinical-communication/',
            name: 'Clinical Communication',
            component: ClinicalCommunication
        },
        {
            path: '/links-to-resources/gmc-resources-for-doctors/',
            name: 'GMC Resources',
            component: GmcResources
        },
        {
            path: '/help/',
            name: 'help',
            component: HELP
        },
        {
            path: '/Donation/',
            name: 'Donation',
            component: Donation
        },
        {
            path: '/Englishpractice/',
            name: 'Englishpractice',
            component: Englishpractice
        },
        {
            path: '/Consultants/',
            name: 'Consultants',
            component: Consultants
        },
        {
            path: '/GeneralPractitioners/',
            name: 'GeneralPractitioners',
            component: GeneralPractitioners
        },
        {
            path: '/JuniorDoctors/',
            name: 'JuniorDoctors',
            component: JuniorDoctors
        },
        {
            path: '/MedicalStudents/',
            name: 'MedicalStudents',
            component: MedicalStudents
        },
        {
            path: '/OtherProfessionals/',
            name: 'OtherProfessionals',
            component: OtherProfessionals
        },
        {
            path: '/Contact/',
            name: 'Contact',
            component: Contact
        }

    ]
};
