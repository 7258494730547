import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/AdminLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Dashboards/HomePage');
const Logout = () => import('../views/Auth/Login/Logout');

// Admin Section

const MasterData = () => import(/* webpackChunkName: "home" */'../views/Admin/DirectorFunctionalDivison/MasterData/ViewPage');
const UserManagement = () => import(/* webpackChunkName: "home" */'../views/Admin/DirectorFunctionalDivison/UserManagement/UserListingPage');
const UserManagementDetails = () => import(/* webpackChunkName: "home" */'../views/Admin/DirectorFunctionalDivison/UserManagement/DetailsPage');
const EnrollmentShipStatistics = () => import(/* webpackChunkName: "home" */'../views/Admin/EnrollmentFunctionalDivison/MemberStatistics');
const PendingMemberManagement = () => import(/* webpackChunkName: "home" */'../views/Admin/EnrollmentFunctionalDivison/UserManagement/PendingMemberViewPage');
const EnrollmentManagement = () => import(/* webpackChunkName: "home" */'../views/Admin/EnrollmentFunctionalDivison/UserManagement/ViewPage');
const EnrollmentUserHistory = () => import(/* webpackChunkName: "home" */'../views/Admin/EnrollmentFunctionalDivison/UserManagement/Components/ProfileDetailsComponent/ProfileHistoryDetails');
const EnrollmentManagementDetails = () => import(/* webpackChunkName: "home" */'../views/Admin/EnrollmentFunctionalDivison/UserManagement/DetailsComponents');
const ClinicalOETFunctionDashBoard = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/DashBoard');
const ClinicalOETMembersManagement = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/MembersManagement');
const ClinicalOETMembersDetails = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/MemberDetails');
const ClinicalOETMembersHistory = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/ProfileDetailsComponent/ProfileHistoryDetails');
const ClinicalOETTeachingLinks = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/TeachingLinks/ListingComponent');
const ClinicalOETTimeTable = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/TimeTable');
const ClinicalOETAttendanceAdd = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/Attendance/AddAttendance');
const ClinicalOETAttendance = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/Attendance/Attendance');
const ClinicalOETAlumniDetailsList = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/AlumniDetailsList');
const ClinicalOETReassignBatch = () => import(/* webpackChunkName: "home" */'../views/Admin/ClinicalAndOetComponents/ReassignBatch');

const PolicyList = () => import(/* webpackChunkName: "home" */'../views/Admin/Policies/ListingComponent');
const PolicyDetails = () => import(/* webpackChunkName: "home" */'../views/Admin/Policies/PolicyDetails');
const AdminProfile = () => import(/* webpackChunkName: "home" */'../views/Admin/MyProfile');

// Member Section

const MemberAttendance = () => import(/* webpackChunkName: "home" */'../views/Members/Attendance');
const MemberEvents = () => import(/* webpackChunkName: "home" */'../views/Members/Events');
const MemberProfile = () => import(/* webpackChunkName: "home" */'../views/Members/MyProfile');
const MemberPolicies = () => import(/* webpackChunkName: "home" */'../views/Members/PoliciesListingComponent');

// Staff Section

const StaffProfile = () => import(/* webpackChunkName: "home" */'../views/Staffs/MyProfile');
const StaffPolicies = () => import(/* webpackChunkName: "home" */'../views/Staffs/Policies/ListingComponent');
const TeachingLinks = () => import(/* webpackChunkName: "home" */'../views/Staffs/TeachingLinks/ListingComponent');
const TeachingLinksDetails = () => import(/* webpackChunkName: "home" */'../views/Staffs/TeachingLinks/TeachingDetails');
const StaffVideo = () => import(/* webpackChunkName: "home" */'../views/Staffs/StaffVideos/VideoListing');
const FileManagement = () => import(/* webpackChunkName: "home" */'../views/Staffs/FileManagement/FolderList');
const StaffFileManagement = () => import(/* webpackChunkName: "home" */'../views/Admin/DirectorFunctionalDivison/FileManagement/FolderList');

export default {
    path: '/admin/',
    name: 'AdminLayout',
    redirect: '/dashboard/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/dashboard/',
            name: 'Home',
            component: Home
        },
        {
            path: '/logout/',
            name: 'Logout',
            component: Logout
        },
        {
            path: '/director-function/master-data/',
            name: 'MasterData',
            component: MasterData
        },
        {
            path: '/director-function/user-management/',
            name: 'UserManagement',
            component: UserManagement
        },
        {
            path: '/director-function/user-management/:id/details/',
            name: 'UserManagementDetails',
            component: UserManagementDetails
        },
        {
            path: '/enrollment-function/statistics/',
            name: 'MemberShipStatistics',
            component: EnrollmentShipStatistics
        },
        {
            path: '/enrollment-function/user-management/',
            name: 'EnrollmentManagement',
            component: EnrollmentManagement
        },
        {
            path: '/enrollment-function/user-management/:id/history/',
            name: 'EnrollmentUserHistory',
            component: EnrollmentUserHistory
        },
        {
            path: '/enrollment-function/pending-member-management/',
            name: 'PendingMemberManagement',
            component: PendingMemberManagement
        },
        {
            path: '/enrollment-function/user-management/:id/details/',
            name: 'EnrollmentManagementDetails',
            component: EnrollmentManagementDetails
        },
        {
            path: '/clinical-function/dash/',
            name: 'ClinicalFunctionDashBoard',
            component: ClinicalOETFunctionDashBoard
        },
        {
            path: '/clinical/member-management/',
            name: 'ClinicalOETMembersManagement',
            component: ClinicalOETMembersManagement
        },
        {
            path: '/clinical/member/:id/details/',
            name: 'ClinicalOETMembersDetails',
            component: ClinicalOETMembersDetails
        },
        {
            path: '/clinical/member-profile/:id/history/',
            name: 'ClinicalOETMembersHistory',
            component: ClinicalOETMembersHistory
        },
        {
            path: '/clinical/teaching-links/',
            name: 'ClinicalOETTeachingLinks',
            component: ClinicalOETTeachingLinks
        },
        {
            path: '/clinical/time-table/',
            name: 'ClinicalOETTimeTable',
            component: ClinicalOETTimeTable
        },
        {
            path: '/clinical/attendance/',
            name: 'ClinicalOETAttendance',
            component: ClinicalOETAttendance
        },
        {
            path: '/clinical/attendance-add/',
            name: 'ClinicalOETAttendanceAdd',
            component: ClinicalOETAttendanceAdd
        },
        {
            path: '/clinical/reassign-batch/',
            name: 'ClinicalOETReassignBatch',
            component: ClinicalOETReassignBatch
        },
        {
            path: '/oet-function/dash/',
            name: 'ClinicalFunctionDashBoard',
            component: ClinicalOETFunctionDashBoard
        },
        {
            path: '/oet/member-management/',
            name: 'ClinicalOETMembersManagement',
            component: ClinicalOETMembersManagement
        },
        {
            path: '/oet/member/:id/details/',
            name: 'ClinicalOETMembersDetails',
            component: ClinicalOETMembersDetails
        },
        {
            path: '/oet/member-profile/:id/history/',
            name: 'ClinicalOETMembersHistory',
            component: ClinicalOETMembersHistory
        },
        {
            path: '/oet/teaching-links/',
            name: 'ClinicalOETTeachingLinks',
            component: ClinicalOETTeachingLinks
        },
        {
            path: '/oet/time-table/',
            name: 'ClinicalOETTimeTable',
            component: ClinicalOETTimeTable
        },
        {
            path: '/oet/attendance/',
            name: 'ClinicalOETAttendance',
            component: ClinicalOETAttendance
        },
        {
            path: '/oet/alumni-details-list/',
            name: 'ClinicalOETAlumniDetailsList',
            component: ClinicalOETAlumniDetailsList
        },
        {
            path: '/oet/attendance-add/',
            name: 'ClinicalOETAttendanceAdd',
            component: ClinicalOETAttendanceAdd
        },
        {
            path: '/oet/reassign-batch/',
            name: 'ClinicalOETReassignBatch',
            component: ClinicalOETReassignBatch
        },

        // Common Components
        {
            path: '/admin/policy-list/',
            name: 'PolicyList',
            component: PolicyList
        },
        {
            path: '/admin/policy/:id/details/',
            name: 'PolicyDetails',
            component: PolicyDetails
        },
        {
            path: '/admin/my-profile/',
            name: 'AdminProfile',
            component: AdminProfile
        },

        //    Member Section
        {
            path: '/member/attendance/',
            name: 'MemberAttendance',
            component: MemberAttendance
        },
        {
            path: '/member/events/',
            name: 'Member',
            component: MemberEvents
        },
        {
            path: '/member/my-profile/',
            name: 'Member',
            component: MemberProfile
        },
        {
            path: '/member/policies/',
            name: 'Member',
            component: MemberPolicies
        },

        //    Staff Section
        {
            path: '/staff/my-profile/',
            name: 'StaffProfile',
            component: StaffProfile
        },
        {
            path: '/staff/policies/',
            name: 'StaffPolicies',
            component: StaffPolicies
        },
        {
            path: '/staff/teaching-links/',
            name: 'TeachingLinks',
            component: TeachingLinks
        },
        {
            path: '/staff/teaching/:id/details/',
            name: 'TeachingLinks',
            component: TeachingLinksDetails
        },
        {
            path: '/staff/video/',
            name: 'StaffVideo',
            component: StaffVideo
        },
        {
            path: '/staff/file-management/',
            name: 'StaffFileManagement',
            component: StaffFileManagement
        },
        {
            path: '/file-management/',
            name: 'FileManagement',
            component: FileManagement
        }
    ]
};
