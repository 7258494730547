const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
const Login = () => import('../views/Auth/Login/Login');
const ForgotPassword = () => import('../views/Auth/Login/ForgotPassword');
const ResetPassword = () => import('../views/Auth/Login/ResetPassword');
const CheckUser = () => import('../views/Auth/Login/LoginCheck');
const Registration = () => import('../views/Auth/Registration/Registration');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        {
            path: '/login/',
            name: 'Login',
            component: Login
        },
        {
            path: '/register/',
            name: 'Registration',
            component: Registration
        },
        {
            path: '/forgot-password/',
            name: 'ForgotPassword',
            component: ForgotPassword
        },
        {
            path: '/check-user/',
            name: 'CheckUser',
            component: CheckUser
        },
        {
            path: '/reset-password/',
            name: 'ResetPassword',
            component: ResetPassword
        }
    ]
};
