<template>
    <router-view/>
</template>
<style scoped>
body {
    margin: 0;
    padding: 0;
    line-height: 2;
    overflow-x: hidden;
}
</style>
<script>
export default {
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            console.log('scroll', event);
            console.log(window.scrollY);
            // event.target.classList.add('nav-lg-white');
            const navContainEl = document.querySelector('.nav-container');
            if (window.scrollY > 50 && !navContainEl.classList.contains('nav-lg-white')) {
                navContainEl.classList.add('nav-lg-white');
            } else if (window.scrollY < 50 && navContainEl.classList.contains('nav-lg-white')) {
                navContainEl.classList.remove('nav-lg-white');
            }
        }
    }
};
</script>
