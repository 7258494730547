<template>
    <div class="share-buttons pt-4">
        <span class="poppins-light-light">SHARE THIS:</span>
        <div class="fl-x fl-j-s lg-fl-j-s mb-4 mb-lg-0 pt-2">
            <div class="mr-2">
                <!--            <btn icon="fa fa-twitter" design="animated-a" text="Twitter"></btn>-->
                <a target="_blank" href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                   class="lego-btn btn-animated-a btn-color-primary round-1 bs-1 bs-1-h"
                   data-size="large"
                   data-show-count="false"><i class="fa fa-twitter mr-2"></i>Tweet</a></div>
            <div>
                <!--            <btn design="animated-a" icon="fa fa-facebook-f" text="Facebook"></btn>-->
                <div class="fb-share-button " data-href="https://www.youtube.com/watch?v=hq3yfQnllfQ"
                     data-layout="button_count" data-size="large"><a target="_blank"
                                                                     href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                                                                     class="lego-btn btn-animated-a btn-color-primary round-1 bs-1 bs-1-h"><i
                    class="fa fa-facebook-f mr-2"></i>Share</a></div>

<!--                <div class="fb-share-button" data-href="https://www.youtube.com/watch?v=hq3yfQnllfQ"-->
<!--                     data-layout="button_count" data-size="small"><a target="_blank"-->
<!--                                                                     href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dhq3yfQnllfQ&amp;src=sdkpreparse"-->
<!--                                                                     class="fb-xfbml-parse-ignore">Share</a></div>-->

<!--                <a href="www.facebook.com" target="_blank" alt="Share on Facebook"-->
<!--                   @click="fbshareCurrentPage">Facebook</a>-->

<!--                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.npmjs.com/package/vue-social-sharing" target="_blank">-->
<!--                    Share on Facebook-->
<!--                </a>-->

            </div>
        </div>
    </div>

</template>
<style lang="scss">

@media (min-width: 992px) {
    .share-buttons {
        width: 45%;
    }
}

.share-buttons::before {
    content: '';
    display: inline-block;
    position: absolute;
    border-top: .1rem solid rgba(0, 0, 0, 0.26);
    width: 14rem;
    //margin:0 1rem;
    transform: translateY(-1rem);
}

//.share-buttons {
//    width: 100%;
//}

</style>
<script>
export default {
    methods: {
        fbshareCurrentPage () {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href) + '&t=' + document.title, '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
            return false;
        }
    },
    computed: {
        currentUrl () {
            return window.location.href;
        }
    }
};

</script>
